import axios from "axios";
import { BACKEND_SERVER_URL } from "constants/api.constant";
import { PERSIST_STORE_NAME } from "constants/app.constant";
import deepParseJson from "utils/deepParseJson";

const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
const persistData = deepParseJson(rawPersistData);
const accessToken = persistData?.auth?.session?.token;

const api = axios.create({
  baseURL: `${BACKEND_SERVER_URL}admin`,
  headers: {
    // Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Add an Axios interceptor to dynamically set the Authorization header before each request
api.interceptors.request.use(
  (config) => {
    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
    const persistData = deepParseJson(rawPersistData);
    const accessToken = persistData?.auth?.session?.token;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add Response Interceptor For Manage isAuth Logic Centrally
api.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = response;
    const responseData = result.data;

    if (Object.keys(responseData).includes("isAuth")) {
      const isAuth =
        typeof responseData?.isAuth === "string"
          ? responseData === "false"
          : !responseData?.isAuth;

      if (isAuth) {
        localStorage.clear();
        window.location.reload();
      }
    }
    return result;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
